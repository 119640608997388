





































































































































































































.create-organization {
  margin-bottom: 50px;
}

.organizations {
  &-body {
    margin-top: 30px;
    padding: 0 16px;

    &-search {
      margin-bottom: 20px;
    }

    &-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
    }
  }
}
